@charset 'UTF-8';

// .copyright-container の横幅
// .container と合わせたい場合は $container_width_array を使用する
//
$copyright_container_width_array: (
  'sm': map-get( $container_width_array, sm ),
  'md': map-get( $container_width_array, md ),
  'lg': map-get( $container_width_array, lg )
) !default;



$copyright_container_min_width_array: (
  'sm': map-get( $container_min_width_array, sm ),
  'md': map-get( $container_min_width_array, md ),
  'lg': map-get( $container_min_width_array, lg )
) !default;



// .copyright-container の左右の padding
// .container と合わせたい場合は $container_padding_array を使用する
//
$copyright_container_padding_array: (
  'sm': map-get( $container_padding_array, sm ),
  'md': map-get( $container_padding_array, md ),
  'lg': map-get( $container_padding_array, lg )
) !default;



// copyright の background-color
//
$background_copyright: transparent !default;
