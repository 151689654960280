@charset 'UTF-8';

// wrapper
// -------
.global-nav-wrapper {
  display: none;
  position: relative;
  @include media(lg){
    background: $background-header;
    border-bottom: 1px solid $gray-400;
    display: block;
  }
}

// メディアクエリによる可視と不可視
// ------------------------
.sm-nav {
  display: block;
  @include media(lg) {
    display: none;
  }
}

// navigation
// ----------
.global-menu {
  background: $base-color;
  display: none;
  position: absolute;
  top: map-get( $header_height_array, sm ) + 5px;
  right: 0;
  width: 100%;
  height: auto;
  @include media(md){
    top: map-get( $header_height_array, md ) + 5px;
  }
  z-index: 2;
  > li {
    position: relative;
    text-align: center;
    > a,
    > .trigger {
      color: $white-color;
      cursor: pointer;
      display: block;
      font-size: 1em;
      padding: 1.2em;
      position: relative;
    }
    .trigger:after {
      font-family: $icon-font-family;
      content: '\f107';
      color: $white-color;
      display: inline-block;
      font-size: 1.4em;
      font-weight: 900;
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
    //&:last-child {
    //  background: $white-color;
    //  padding: 1em 0;
    //}
  }
  // PC用
  @include media(lg) {
    background: transparent;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    position: relative;
    top: 0;
    width: 100%;
    height: auto;

    > li {
      border-bottom: none;
      flex: 1;
      height: auto;
      line-height: 1;
      position: relative;
      &:not(:first-child) { border-left: 1px dashed $gray-400; }
      > a,
      > .trigger {
        color: $body-text-color;
        display: block;
        font-size: 16px;
        font-weight: 700;
        margin: .5em 0;
        padding: .875em 0;
        text-align: center;
        transition: color $transition;
        &:hover {
          color: $base-color;
        }
      }
      .trigger:after {
        content: none;
      }
      &:last-child {
        background: inherit;
        padding: 0;
      }
    }
  } // PC
}

// 子メニュー
//
.child {
  background: darken( $base-color, 5% );
  display: none;
  &.active {
    display: block;
  }
  li {
    font-size: 1em;
    a {
      color: #fff;
      display: block;
      padding: 1.2em 1em 1.2em 2em;
    }
  }
  // PC用
  @include media(lg) {
    background: transparent;
    display: block;
    opacity: 0;
    position: absolute;
    top: 52px;
    left: 50%;
    transform: translateX(-50%);
    transition: .2s visibility linear, .2s opacity linear;
    visibility: hidden;
    width: 120%;
    z-index: 99;
    &:before { // 吹き出しの口
      position: absolute;
      content: '';
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-bottom: 8px solid rgba( #000, .8 );
      width: 0;
      height: 0;
    }
    li {
      background: rgba( #000, .8 );
      transition: background $transition;
      text-align: center;
      position: relative;
      &:first-child {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
      &:hover {
        background: rgba( #000, .85 );
      }
      a {
        color: $white-color;
        display: block;
        font-size: 1.4rem;
        padding: 1em;
        @include media(lg) {
          padding: 1.8em;
          span {
            transition: .2s linear;
          }
          &:hover {
            text-decoration: none;
            & span {
              border-bottom: 3px solid $base-color;
              padding-bottom: .6em;
            }
          }
        }
      }
    }
  }
}



// hover で子メニューを表示
@include media(lg) {
  .global-menu > li:hover .child {
    visibility: visible;
    opacity: 1;
  }
}
