@charset 'UTF-8';

// header
//
header {
  background: transparent;
  padding-bottom: map-get( $header_padding_bottom_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, header );
  @include media(md) {
    padding-bottom: map-get( $header_padding_bottom_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $header_padding_bottom_array, lg );
  }
}



// header-wrapper
//
.header-wrapper {
  background: $background_header;
  border-top: 4px solid $base-color;
  position: relative;
  padding: 0 map-get( $header_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $header_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $header_container_padding_array, lg );
  }
  @include width_wrapper( $header_container_min_width_array, $header_container_padding_array );
}



// header-container
//
.header-container {
  margin: 0 auto;
  width: 100%;
  @include media(lg) {
    padding: 2em 0;
  }
}



// header-inner
//
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-get( $header_height_array, sm );
  @include media(md) {
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    height: auto;
  }
}



// header-inner-left
// header-inner に内包して使用する
//
.header-inner-left {
  flex: 1 auto;
}

// header-title
//
.header-title {
  display: flex;
  align-items: center;
  img {
    display: block;
    margin-right: .5em;
    width: auto;
    height: 28px;
    max-width: none;
    @include media(md){
      height: 38px;
    }
    @include media(lg){
      height: 48px;
    }
  }
}

.header-title-lower {
  display: none;
  @include media(lg){
    display: block;
    margin-top: 1em;
  }
  .dept {
    color: $gray-700;
    font-size: .875em;
    font-weight: 700;
  }
}

.header-logo-text {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4;
  &_sub {
    color: $gray-600;
    font-size: 64%;
  }
  &_name {
    color: $base-color;
  }
  @include media(md){
    font-size: 1.6rem;
  }
  @include media(lg){
    font-size: 2rem;
  }
}



// header-inner-right
// header-inner に内包して使用する
//
.header-inner-right {
  flex: 0 0 auto;
}

.header-address-wrapper {
  display: none;
  @include media(lg){
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.header-address-container {
  display: block;
}

.header-address-tel,
.header-address-fax {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 8px;
}

.header-address-text {
  display: block;
  font-size: 1.3rem;
  font-weight: normal;
  letter-spacing: .07em;
}



.header-utility-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(lg){
    margin-right: 1em;
  }
}

.utility-btn-items {
  padding: 0 .5em;
}



$base-net-color:  $base-color;
$base-line-color: #07b53b;

.utility-btn {
  background: $base-net-color;
  border-radius: .25em;
  color: $white-color;
  display: block;
  line-height: 1;
  padding: 1em;
  text-align: center;
  transition: background $transition;
  &:hover {
    background: lighten($base-net-color, 10%);
    color: $white-color;
  }
  &.line {
    background: $base-line-color;
    &:hover {
      background: lighten($base-line-color, 10%);
    }
  }

  span {
    display: block;
  }

  .upper-icon {
    font-size: 2em;
  }

  .lower-text {
    font-size: .875em;
    font-weight: 700;
    margin-top: .65em;
  }
}
