@charset 'UTF-8';

// heading initialize
//
h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}



// heading
//
h1 {
  font-size: $h1-font-size;
  &.top-heading {
    border-left: 3px solid $base-color;
    color: $gray-600;
    font-size: 1.384615em;
    margin-bottom: .5em;
    padding: 0 0 .1em .4em;
  }
  &.first {
    border-left: 1px solid $base-color;
    color: $gray-600;
    padding: 0 0 .1em .4em;
  }
}

h2 {
  font-size: $h2-font-size;
  &.first {
    background: $white-color;
    border: 1px solid #ccc;
    position: relative;
    padding: .75em 1em .75em 1.5em;
    margin-bottom: .8em;
    &:after {
      position: absolute;
      top: .5em;
      left: .5em;
      content: '';
      width: 6px;
      height: calc( 100% - 1em );
      background: $third-color;
      border-radius: 4px;
    }
  }
  &.side-heading {
    color: $gray-600;
    display: inline-block;
    font-size: $h2-font-size * .75;
    margin-bottom: 1em;
    position: relative;
    &:after {
      content: '';
      border-bottom: 2px solid $third-color;
      display: block;
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 50%;
    }
  }
}

h3 {
  font-size: $h3-font-size;
  &.first {
    color: #555;
    position: relative;
    margin-top: 3em;
    margin-bottom: 1em;
    &::before {
      content: '';
      position: absolute;
      bottom: -1rem;
      width: 6rem;
      border-bottom: 3px solid $base-color;
    }
  }
  &.cooperate {
    color: $base-color;
  }
  &.prefecture {
    border-bottom: 1px solid $base-color;
    color: $base-color;
    font-size: 1.6em;
    margin-bottom: 1em;
    .grid + & {
      margin-top: 2em;
    }
  }
}

h4 {
  font-size: $h4-font-size;
  &.first {
    border-bottom: 1px dotted $base-color;
    color: $first-color;
    margin-bottom: .4em;
    padding-bottom: .4em;
  }
  &.kanren-link-facility {
    background: $second-color;
    border-radius: 4px;
    color: $white-color;
    font-size: .925em;
    margin-bottom: 1em;
    padding: .5em 1em;
  }
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}



// paragraph
//
p {
  font-size: inherit;
  letter-spacing: map-get( $p_letter_spacing_array, sm );
  line-height: map-get( $p_line_height_array, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-get( $p_letter_spacing_array, md );
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    letter-spacing: map-get( $p_letter_spacing_array, lg );
    line-height: map-get( $p_line_height_array, lg );
  }
}



// フォントファミリーを日本語に限定する
//
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// 強制改行
.sp-br {
  display: block;
  @include media(md){
    display: inline;
  }
}



// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
//
.phrase {
  margin-bottom: 0 !important;
}



// イタリック
//
i {
  font-style: italic;
  line-height: inherit;
}



// ボールド
//
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}



figcaption {
  color: $gray-600;
  font-size: .875em;
  text-align: center;
  margin-top: 1em;
}
