@charset 'UTF-8';

// copyright-wrapper
//
.copyright-wrapper {
  background: $background_copyright;
  flex: none;
  padding: .8em map-get( $copyright_container_padding_array, sm );
  position: relative;
  width: 100%;
  z-index: map-get( $z_index_array, copyright );
  @include media(md) {
    padding: .8em map-get( $copyright_container_padding_array, md );
  }
  @include media(lg) {
    padding: .8em map-get( $copyright_container_padding_array, lg );
  }
  @include width_wrapper( $copyright_container_min_width_array, $copyright_container_padding_array );
}



// copyright-container
//
.copyright-container {
  margin: 0 auto;
  @include width_container( $copyright_container_width_array );
}



// copuright
//
.copyright {
  color: $gray-600;
  font-size: 80%;
  text-align: center;
}
