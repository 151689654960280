@charset 'UTF-8';

// COMMON LAYOUT
// ---------------------------------------------------------------------------------------------------------------- //

.main-contents-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

// main column
.main-column {
  order: 0;
  width: 100%;
  @include media(md){
    flex: 1 0 1%;
  }
  @include media(lg){
    order: 1;
  }
  section + section {
    margin-top: map-get( $section_gutter_array, sm ) * 2;
    @include media(md){
      margin-top: map-get( $section_gutter_array, md ) * 2;
    }
    @include media(lg){
      margin-top: map-get( $section_gutter_array, lg ) * 2;
    }
  }
}

// side column
.side-column {
  margin-top: 2em;
  order: 1;
  width: 100%;
  @include media(lg){
    order: 0;
    margin-top: 0;
    margin-right: 36px;
    width: 242px;
  }
}
// Banner
.side-column-banner {
  width: 100%;
  margin: 2em auto;
  @include media(md) {
    width: 100%;
  }
  @include media(lg) {
    width: 200px;
  }
}

// Menu List
.side-column-menu-list {
  background: $white-color;
  width: 100%;
  li {
    position: relative;
    width: 90%;
    margin: 0 auto;
    &:before {
      content: '';
      background: $base-color;
      display: inline-block;
      width: 3px;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:not(:last-child) {
      border-bottom: 1px solid $gray-300;
    }
    a {
      color: $gray-800;
      display: block;
      font-weight: 700;
      padding: 1em;
      transition: background $transition;
      &:hover {
        background: #F9F9FA;
        text-decoration: none;
      }
    }
  }
}

// トピックスボタン
.topics-button-wrapper {
  position: relative;
}

.topics-inner-anchor {
  background: $white-color;
  border: 4px solid $base-color;
  color: $base-color;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  transition: box-shadow $transition;
  &:hover {
    box-shadow: 6px 6px #dfe2e4;
    color: $base-color;
  }
  span {
    color: $gray-700;
    display: block;
    font-size: .75em;
    margin-top: .5em;
  }
  &._covid {
    border: 4px solid $red-color;
    color: $red-color;
    //@include media(md){
    //  flex-direction: row;
    //  justify-content: space-between;
    //  padding: 2em 1em;
    //  span {
    //    margin-top: 0;
    //  }
    //}
  }
}


// TOP PAGE
// ---------------------------------------------------------------------------------------------------------------- //

// Topics Elements
.topics-element {
  background: $white-color;
  height: 100%;
  position: relative;

  > a {
    border-top: 4px solid $base-color;
    color: $body-text-color;
    display: block;
    height: 100%;
    padding-top: 1em;
    transition: background $transition;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: $base-color;
      border-top: 4px solid lighten( $base-color, 20% );
      color: $white-color;
    }
  }

  &_image {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: 140px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate( -50%, -50% );
    }
    @include media(md) {
      width: 180px;
      height: 180px;
    }
  }

  &_body {
    font-size: .875em;
    line-height: 1.5;
    padding: 1em;
    .title {
      font-size: 1.25em;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: .4em;
    }
  }
}

// News Elements
.news-element {
  background: $white-color;
  height: 100%;
  position: relative;

  > a {
    color: $gray-700;
    display: block;
    height: 100%;
    transition: background .25s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: #edf9f9;
    }
  }

  &_tag {
    background: $base-color;
    color: $white-color;
    display: table;
    font-size: 87.5%;
    float: right;
    padding: .2em .6em;
    position: absolute;
    top: -5%;
    right: .5em;
    z-index: 1;
  }

  &_body {
    line-height: 1.4;
    padding: 1em;
    &:after {
      content: '\f138';
      color: $gray-300;
      font-family: $icon-font-family;
      font-size: 1.25em;
      font-weight: 900;
      display: block;
      margin-top: .4em;
    }
    .date {
      color: $first-color;
      font-size: 87.5%;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: .4em;
      & span {
        font-size: 140%;
      }
    }
    &._noicon {
      &:after {
        content: none;
      }
    }
  }
}

// 連携
.cooperate-wrapper {
  background: $white-color;
  //margin-top: 2em;
  padding: 1em;
  position: relative;
  @include media(md){
    padding: 2em;
  }
}

.cooperate-heading {
  color: $base-color;
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 1em;
}

.cooperate-image {
  position: relative;
  img {
    width: 96%;
  }
}

.cooperate-anchor {
  background: $base-color;
  color: $white-color;
  display: table;
  font-size: .875em;
  font-weight: 700;
  padding: .8em 1.6em;
  position: absolute;
  bottom: -18px;
  right: 0;
  transition: background $transition;
  @include media(lg){
    font-size: .8em;
    padding: .6em 1.4em;
  }
  &:hover {
    background: darken( $base-color, 5% );
    color: $white-color;
  }
}

// Slider 内のテキスト
.title-container {
  background: rgba( 255, 255, 255, .7 );
  padding: 1em 2em;
  position: absolute;
  bottom: 2.5em;
  right: 0;
  z-index: 9;
  @include media(md){
    bottom: 0em;
  }
}

.clinic-name {
  font-size: 1.4em;
}

.clinic-date {
  color: $first-color;
  font-size: 2.3em;
  font-weight: 700;
}



// SUB PAGE
// ---------------------------------------------------------------------------------------------------------------- //

// hero
.hero {
  color: inherit;
  position: relative;
  width: 100%;

  &_title {
    font-size: 180%;
    line-height: 1.2;
    position: absolute;
    top: 50%;
    left: .5em;
    transform: translateY(-50%);
    @include media(md) {
      font-size: $h1-font-size;
    }
    @include media(lg) {
      font-size: $h1-font-size;
    }
  }
}

// Access
.access-google-map {
  margin-bottom: 1.2em;
  width: 100%;
  height: 280px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.caption {
  font-size: 1.2em;
  font-weight: 700;
  margin-top: .5em;
  text-align: right;
  .title {
    display: inline-block;
    font-size: .75em;
    font-weight: 300;
    margin-right: .4em;
  }
}

// チャート
.chart {
  li {
    //background: $white-color;
    //border: 1px solid $fourth-color;
    position: relative;
    & + li {
      margin-top: 4em;
      @include media(md) {
        margin-top: 5em;
      }
      @include media(lg) {
        margin-top: 6em;
      }
      &:before {
        font-family: $icon_font_family;
        content: '\f13a';
        color: $first-color;
        display: block;
        font-size: 2em;
        font-weight: 900;
        position: absolute;
        top: -1.425em;
        left: 50%;
        transform: translateX(-50%);
        @include media(md) {
          top: -1.6em;
        }
        @include media(lg) {
          top: -1.925em;
        }
      }
    }
  }
}

// 電話再診のながれ
.telexam-chart {
  background: $white-color;
  display: block;
  text-align: center;
  @include media(lg) {
    display: flex;
    text-align: left;
  }
  dt {
    background: $fourth-color;
    color: #fff;
    padding: .4em 0;
    text-align: center;
    @include media(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 18rem;
    }
  }
  dd {
    padding: 1em;
    @include media(lg) {
      flex: 1;
    }
  }
}



// フォームのラッパー
.form-wrapper {
  background: $white-color;
  padding: 2em 1em;
  @include media(md){
    padding: 3em;
  }
  @include media(lg){
    padding: 3em 6em;
  }
}


// 関連リンク
.kanren-link {

  li {
    &:not(:first-child){
      margin-top: .8em;
    }

    a {
      color: $base-color;
    }
  }
}


// 囲み
.column-box {
  background: $white-color;
  border: 3px solid $base-color;
  margin: 1em 0;
}

.column-box-header {
  background: $base-color;
  border-radius: 0 0 8px 0;
  color: $white-color;
  display: inline-block;
  font-size: 1.25em;
  font-weight: 700;
  padding: calc( .5em - 3px ) 1em .5em;
}

.column-box-body {
  padding: 1em;
}

// YouTube
.top-youtube-wrapper {
  margin-top: 2.5em;
  position: relative;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
}

.iframe-inner {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



// 診療担当医表 2021.08.10
//
table.schedule-table-charge {
  font-size: .875em;
  thead th {
    font-size: .875em;
  }

  td {
    width: percentage(1/7);
  }
}

.charge-item {
  & + & {
    border-top: 1px dotted $gray-500;
    margin-top: .5em;
    padding-top: .5em;
  }
  text-align: center;
  // 診療科名
  .section {
    color: $base-color;
    font-weight: 500;
    span {
      display: block;
      font-size: .75em;
    }
    &.junkan {
      color: $first-color;
    }
    &.kokyu,
    &.noshinkei {
      color: $fourth-color;
    }
  }
  // 医師
  .doctor {
    color: $gray-600;
    font-size: .875em;
    font-weight: 700;
  }
}



// ネット予約・友だち追加
//
$item-link-height-sm: 44px;
$item-link-height-lg: 48px;


.common-conversion-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 6em;
  right: 0;
  z-index: 1;
  @include media(lg){
    top: 20em;
  }
}

.common-conversion-item {
  border-radius: 8px 0 0 8px;
  box-shadow: 0px 0px 4px 1px #c5c5c5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  transform: translateX(calc(100% - #{$item-link-height-sm}));
  transition: transform $transition;
  & + & {
    margin-top: 6px;
  }
  &.active {
    transform: translateX(0);
  }
}

// a
.item-link {
  color: $white-color;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: $item-link-height-sm;
  padding: 0 1em;
  transition: background $transition;
  @include media(lg){
    line-height: $item-link-height-lg;
  }

  &.__net {
    background: lighten( $base-net-color, 5% );
  }
  &.__line {
    background: lighten( $base-line-color, 5% );
  }

  &:hover {
    color: $white-color;
    &.__net {
      background: lighten( $base-net-color, 10% );
    }
    &.__line {
      background: lighten( $base-line-color, 10% );
    }
  }
}

// icon
.conversion-icon {
  flex: 0 0 auto;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: $item-link-height-sm;
  height: $item-link-height-sm;
  @include media(lg){
    width: $item-link-height-lg;
    height: $item-link-height-lg;
  }

  &.__net {
    background: $base-net-color;
  }
  &.__line {
    background: $base-line-color;
  }

  i {
    color: $white-color;
    font-size: 2em;
    @include media(lg){
      font-size: 1.875em;
    }
  }
}
