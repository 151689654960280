@charset 'UTF-8';

// footer
//
footer {
  background: transparent;
  flex: none;
  padding-top: map-get( $footer_padding_top_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, footer );
  @include media(md) {
    padding-top: map-get( $footer_padding_top_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $footer_padding_top_array, lg );
  }
}



// footer-wrapper
//
.footer-wrapper {
  background: $background_footer;
  border-top: 1px solid $gray-200;
  position: relative;
  padding: 2em map-get( $footer_container_padding_array, sm );
  @include media(md) {
    padding: 2em map-get( $footer_container_padding_array, md );
  }
  @include media(lg) {
    padding: 2em map-get( $footer_container_padding_array, lg );
  }
  &._lower {
    padding: .6em map-get( $footer_container_padding_array, sm );
    @include media(md) {
      padding: .6em map-get( $footer_container_padding_array, md );
    }
    @include media(lg) {
      padding: .6em map-get( $footer_container_padding_array, lg );
    }
  }
  @include width_wrapper( $footer_container_min_width_array, $footer_container_padding_array );
}



// footer-container
//
.footer-container {
  margin: 0 auto;
  @include width_container( $footer_container_width_array );
}



// footer-inner
//
.footer-inner {
  display: flex;
  font-size: .8em;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

.footer-inner-upper {
  display: block;
  margin: ( - grid_margin(sm) );
  @include media(md){
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: ( - grid_margin(md) );
  }
  @include media(lg){
    margin: ( - grid_margin(lg) );
  }
}

.footer-inner-upper-left,
.footer-inner-upper-right {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  padding: grid_margin(sm);
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding: grid_margin(md);
  }
  @include media(lg){
    padding: grid_margin(lg);
  }
}

.footer-inner-upper-right {
  margin-top: 1em;
  @include media(md){
    margin-top: 0;
  }
}

.footer-google-map {
  iframe {
    width: 100%;
    height: 240px;
    @include media(md){
      height: 240px;
    }
    @include media(lg){
      height: 320px;
    }
  }
}

.map-lower-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: .4em;
  @include media(md){
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  p {
    font-size: .875em;
  }
  div {
    margin-top: 1em;
    @include media(md){
      margin-top: 0;
    }
  }
}


.footer-wrapper-lower-list {
  display: flex;
  align-items: center;
  justify-content: center;
  li:not(:last-child){
    margin-right: 1em;
  }
  a {
    color: $gray-600;
  }
  @include media(lg){
    justify-content: start;
  }
}

.footer-company-list {
  line-height: 1.6;
  li {
    font-size: 1em;
    &.logo {
      margin-bottom: 1.2em;
      img {
        width: auto;
        height: 44px;
        @include media(lg){
          height: 56px;
        }
      }
    }
    &.dept {
      color: $gray-600;
      font-size: 1em;
      font-weight: 700;
      margin-bottom: 1em;
    }
    &.address {
      font-size: .875em;
      span {
        display: block;
        @include media(lg){
          display: inline;
        }
      }
    }
    &.tel {
      font-size: 24px;
      margin-top: .5em;
    }
    &.fax {
      font-size: 24px;
    }
  }
}

// 診療時間
.schedule-table {
  color: $base-color;
  font-size: 12px;
  th, td {
    background: $white-color;
    border: 1px solid $gray-400;
    text-align: center;
    vertical-align: middle;
  }
  th {
    color: $gray-700;
    padding: .2em .5em;
  }
  td {
    padding: .5em;
  }
}

.shinryou-time {
  font-size: 12px;
  line-height: 2;
  margin: 0 0 1em;
}

.footer-utility-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(lg){
    justify-content: flex-end;
  }
}
