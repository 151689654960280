@charset 'UTF-8';

// ベースカラーパターン
//
$base-color:    #005830 !default;
$first_color:   #18934c !default;
$second_color:  #d35746 !default;
$third_color:   #7f7f80 !default;
$fourth-color:  #0091C1 !default;
$five-color:    #e0005d !default;

// グレーパターン
//
$gray_100: #f8f9fa !default;
$gray_200: #e9ecef !default;
$gray_300: #dee2e6 !default;
$gray_400: #ced4da !default;
$gray_500: #adb5bd !default;
$gray_600: #6c757d !default;
$gray_700: #495057 !default;
$gray_800: #343a40 !default;
$gray_900: #212529 !default;
$gray-dark: #333 !default;

// 汎用カラー
//
$white_color:  #fff    !default; // 白
$black_color:  #000    !default; // 黒
$red_color:    #b0120a !default; // 赤
$yellow_color: #fff000 !default; // 黄
$orange_color: #f18f2c !default; // 橙
$green_color:  #63a764 !default; // 緑
$blue_color:   #005fcc !default; // 青
$pink_color:   #ff0066 !default; // ピンク



// 上記でカラーを追加した場合、下記の配列にも追加する（ '使用する名前': 変数;
//
$theme_colors_array: (
  'base':   $base_color,
  'first':  $first_color,
  'second': $second_color,
  'third':  $third_color,
  'fourth': $fourth_color,
  'five':   $five_color
) !default;

$gray_colors_array: (
  'gray-100': $gray_100,
  'gray-200': $gray_200,
  'gray-300': $gray_300,
  'gray-400': $gray_400,
  'gray-500': $gray_500,
  'gray-600': $gray_600,
  'gray-700': $gray_700,
  'gray-800': $gray_800,
  'gray-900': $gray_900
) !default;

$common_colors_array: (
  'white':  $white_color,
  'black':  $black_color,
  'red':    $red_color,
  'yellow': $yellow_color,
  'orange': $orange_color,
  'green':  $green_color,
  'blue':   $blue_color,
  'pink':   $pink_color
) !default;

// 上記のカラー配列をまとめる
//
$basic_colors_array: map-merge( $gray_colors_array, $common_colors_array ) !default;
$color_pattern_array: map-merge( $theme_colors_array, $basic_colors_array ) !default;
