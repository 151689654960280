@charset 'UTF-8';

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    li:not(:last-child) {
      margin-bottom: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  &.indent {
    margin-left: 1em;
    ul {
      margin-top: .8em;
    }
  }
}



// ul の装飾
//
ul.disc {
  list-style: disc outside;
  padding-left: 1.4em;
  ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.square {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f0c8';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f111';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.asterisk {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: 'Hiragino Sans', 'メイリオ', Meiryo, sans-serif;
      content: '※';
      color: inherit;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-row {
  display: flex;
  flex-flow: row wrap;
  li {
    &:not(:last-child){
      margin-right: .5em;
    }
  }
}

// ol
//
ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

// dl
//
dl {
  dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
}



// 診療時間のご案内
.consul-heading-list {
  li {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: 1em;
    }
    span {
      display: inline-block;
      flex: 1 0 0;
      &.heading {
        color: $third-color;
        font-weight: 700;
        flex: 0 0 68px;
        @include media(lg){
          flex: 0 0 84px;
        }
      }
    }
  }
}

// 院⻑紹介
.intro-heading-list {
  li {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: .6em;
    }
    span {
      display: inline-block;
      flex: 1 0 0;
      &.heading {
        color: inherit;
        flex: 0 0 96px;
        text-align: justify;
        text-justify: inter-character;
        @include media(lg){
          flex: 0 0 108px;
        }
      }
    }
  }
}
