@charset 'UTF-8';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

address {
  font-style: normal;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
  line-height: inherit;
}

// form
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

button:-moz-focusring,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  border: 0;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}



// HTML
//
html {
  font-size: calc( 1em * 0.625 ); // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  @if ( $fluid_lg == true ) {
    overflow-x: hidden;
  }
}



// body
//
body {
  background: $background_body;
  color: $body_text_color;
  font-family: $font_family;
  font-size: map-get( $base_font_size_array, sm );
  line-height: map-get( $base_line_height, sm );
  letter-spacing: map-get( $body_letter_spacing, sm );
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  // フォントレンダリング設定 - Retina Display
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @include media(md){
    font-size: map-get( $base_font_size_array, md );
    letter-spacing: map-get( $body_letter_spacing, md );
    line-height: map-get( $base_line_height, md );
  }

  @include media(lg){
    font-size: map-get( $base_font_size_array, lg );
    letter-spacing: map-get( $body_letter_spacing, lg );
    line-height: map-get( $base_line_height, lg );
  }
}

// IE11 用 hack
_:-ms-lang(x), body { display: flex; flex-flow: column wrap; }



// typograph
//
b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

i {
  font-style: italic;
  line-height: inherit;
}



// anchor tag
//
a {
  color: $link_color;
  text-decoration: $link_decoration;
  &:hover {
    color: $link_hover_color;
    text-decoration: $link_hover_decoration;
  }
}



// image tag
//
img {
  border-style: none;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}



// button
//
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}


// horizontal line
//
hr {
  border-top: 0;
  border-bottom: $hr_border;
  border-left: 0;
  border-right: 0;
  width: 100%;
  height: 0;
  margin: multi_array( $hr_margin, 'sm', 'top') auto multi_array( $hr_margin, 'sm', 'bottom');
  clear: both;

  @include media(md) {
    margin: multi_array( $hr_margin, 'md', 'top') auto multi_array( $hr_margin, 'md', 'bottom');
  }

  @include media(lg) {
    margin: multi_array( $hr_margin, 'lg', 'top') auto multi_array( $hr_margin, 'lg', 'bottom');
  }
}



// 属性と状態のリセット
//
[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

:focus:not(:focus-visible) {
  outline: none;
}
