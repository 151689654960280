@charset 'UTF-8';



// 2021.10.11
$top-topics-gutter: 2em;

.top-topics-container {
  display: block;
  margin-top: ( - $top-topics-gutter );
  margin-right: ( - $top-topics-gutter / 2 );
  margin-left: ( - $top-topics-gutter / 2 );
  margin-bottom: 3em;
  @include media(lg){
    display: flex;
    justify-content: space-between;
    height: 718px;
    overflow: hidden;
  }
}

.topics-news-wrapper, .topics-info-container {
  padding: $top-topics-gutter ($top-topics-gutter / 2);
}

.topics-news-wrapper {
  flex: 0 0 100%;
  @include media(lg){
    flex: 0 0 50%;
  }
}

.topics-info-container {
  flex: 0 0 100%;
  @include media(lg){
    flex: 1;
  }
}

// お知らせ
.topics-news-container {
  background: $white-color;
  max-height: 240px;
  overflow-y: scroll;
  @include media(lg){
    height: 100%;
    max-height: none;
  }
}

ul.topics-news-list {
  padding: 1em;
  li {
    position: relative;
    &:not(:last-child){
      border-bottom: 1px solid $gray-200;
    }
    a {
      color: $gray-dark;
      display: block;
      transition: background $transition;
      &:hover {
        background: $gray-100;
      }
    }
  }
}

.topics-news-contents {
  display: flex;
  align-items: center;
  padding: 1em;

  span {
    display: block;
    &.new {
      color: $red-color;
      font-weight: 700;
      margin-right: .5em;
    }
    &.date {
      color: $gray-600;
      margin-right: 1em;
    }
    &.title {
      color: $gray-dark;
      font-weight: 700;
    }
  }
}



// 交通アクセス
.lower-access {
  margin-top: $top-topics-gutter * 2;
}
